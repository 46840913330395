import React from "react";
import Layout from "../../components/layout/Layout";
import ResponsiveContainer from "../../components/ui/ResponsiveContainer";

import * as styles from "./styles.module.scss";

const Imprint = () => {
  return (
    <Layout title='Impressum'>
      <ResponsiveContainer>
        <h1>Impressum</h1>
        <div className={styles.imprint}>
          <div className={styles.row}>
            <strong>Vereinsname</strong>
            <span>Verein Wertschätzungsinitiative - Du bist mehr wert</span>
            <p style={{ marginTop: 10 }}>
              Dieser Verein ist gemeinnützig, überparteilich sowie unabhängig und soll
              Arbeitnehmer*innen in jeglicher Form unterstützen, um das Wohlergehen im Berufsleben
              durch erlebte Wertschätzung, Respekt, Toleranz und Menschlichkeit zu fördern.
            </p>
          </div>

          <div className={styles.row}>
            <strong>Anschrift</strong>
            <span>8076 Vasoldsberg</span>
          </div>

          <div className={styles.row}>
            <strong>Aufsichtsbehörde</strong>
            <span>BH Graz - Umgebung</span>
          </div>

          <div className={styles.row}>
            <strong>Kontaktdaten</strong>
            <span>
              Tel:{" "}
              <a className={styles.link} href='tel:+436643252875'>
                +43 664 3252875
              </a>
            </span>
            <span>
              Email:{" "}
              <a className={styles.link} href='mailto:info@dubistmehrwert.at'>
                info@dubistmehrwert.at
              </a>
            </span>
          </div>

          <div className={styles.row}>
            <strong>ZVR-Zahl</strong>
            <span>1244868409</span>
          </div>

          <div className={styles.row}>
            <strong>Anwendbare Rechtsvorschriften und Zugang dazu</strong>
            <a className={styles.link} href='http://www.ris.bka.gv.at/'>
              http://www.ris.bka.gv.at/
            </a>
          </div>

          <div className={styles.row}>
            <strong>Angaben zur Online-Streitbeilegung</strong>
            <p>
              Verbraucher haben die Möglichkeit, Beschwerden an die Online-Streitbeilegungsplattform
              der EU zu richten:{" "}
              <a className={styles.link} href='http://ec.europa.eu/odr'>
                http://ec.europa.eu/odr
              </a>
              . Sie können eine allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse
              richten.
            </p>
          </div>

          <div className={styles.row}>
            <strong>Wissenschaftliche Begleitung</strong>
            <p>IWS - Institut für Wertschätzung</p>
            <p>Donau Universität Krems</p>
          </div>

          {/* <div className={styles.row}>
            <strong>Idee & Konzept</strong>
            <a className={styles.link} href='https://www.enko-consult.at'>
              enko-consult.at
            </a>
          </div> */}

          <div className={styles.row}>
            <strong>Design & Umsetzung</strong>
            <a className={styles.link} href='https://www.southeast.at'>
              southeast.at
            </a>
          </div>

          <div className={styles.row}>
            <strong>Anmerkung zu Mitarbeiter*innen Statements</strong>
            <p>
              Aus datenschutzrechtlichen Gründen wurden die Namen der Mitarbeiter*innen geändert.
            </p>
          </div>

          <div className={styles.row}>
            <strong>Hinweis zu Reisen und Aktionen</strong>
            <p>
              Aus rechtlichen Gründen weisen wir darauf hin, dass für sämtliche auf dieser Website
              angebotenen Reisen und Aktionen sowie andere geldwertige Leistungen, der Verein
              Wertschätzungsinitiative - Du bist mehr wert weder als Veranstalter noch als
              Vermittler auftritt, sondern die Veröffentlichung allein aus Gründen des
              Mitgliederservice durchführt.
            </p>
          </div>
        </div>
      </ResponsiveContainer>
    </Layout>
  );
};

export default Imprint;
